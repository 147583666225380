import React from 'react';
import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <img src="/assets/images/logoo.png" alt="Exterior Flow logo" className={styles.logo} />
          <p className={styles.description}>
          Si kompani e specializuar në printimin dhe printimin 3D, ne ofrojmë teknologjinë më të avancuar dhe mundësi kreative për të përmirësuar biznesin tuaj. <a  href="mailto:info@boldstudio.design">Filloni provën tuaj sot</a> dhe krijoni produkte unike që do të mahnitin çdo klient.
          </p>
          <p className={styles.copyright}>
          © BOLD STUDIO 2024. Të Drejtat e Rezervuara.
          </p>
        </div>

        <div className={styles.rightSection}>
          {/* <div className={styles.column}>
            <h4>More</h4>
            <ul>
              <li><a href="#">About</a></li>
              <li><a href="#">Why Us?</a></li>
              <li><a href="#">Features</a></li>
             
              <li><a href="#">FAQ</a></li>
            </ul>
          </div> */}

          {/* <div className={styles.column}>
            <h4>Utility</h4>
            <ul>
              <li><a href="#">Log in</a></li>
              <li><a href="#">Sign up</a></li>
              <li><a href="#">Reset Password</a></li>
              <li><a href="#">Email Support</a></li>
            </ul>
          </div> */}
        </div>
      </div>

      {/* <div className={styles.bottomLinks}>
        <a href="#">Terms & Conditions</a>
        <a href="#">Subscription Billing Terms</a>
        <a href="#">Privacy Policy</a>
      </div> */}
    </footer>
  );
};

export default Footer;
