import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y, Autoplay } from "swiper/modules"; 

import styles from "./Featured.module.css"
const Features1 = ({ page }) => {
  // console.log(page);
const features = [
    {
        logo:"/assets/images/exlusive.png"
    },
    {
        logo:"/assets/images/goddess.png"
    },
    {
        logo:"/assets/images/elsig.png"
    },
    {
        logo:"/assets/images/boltbox.svg"
    },
    {
        logo:"/assets/images/ikodify.png"
    },
    {
        logo:"/assets/images/thuraja.png"
    },
    {
        logo:"/assets/images/prograf.png"
    },
    {
        logo:"/assets/images/digihive.png"
    },
]
  return (
    <>
      <div className={styles.features}>
        <h2>Siç është Prezantuar Në
        </h2>

        <Swiper
          modules={[Navigation, A11y, Autoplay]}
          
          className=" mySwiper swiperWrapper"
          spaceBetween={0}
          watchOverflow="hidden"
          scrollbar={{ draggable: false }}
          // onSwiper={(swiper) => console.log(swiper)}
          // slideToClickedSlide={true}
          // preventClicksPropagation={false}
          // preventClicks={false}
          // allowTouchMove={false}
          clickable={true}
          centeredSlides={true}
          loop={true}
          speed={3000}
          autoplay={{
            delay: 0,
          }}
          breakpoints={{
            320: {
              slidesPerView: 2.2,
              spaceBetween: 0,
            },
            600: {
              slidesPerView: 3,
              spaceBetween: -10,
            },
            800: {
              slidesPerView: 4,
              spaceBetween: -10,
            },

            1200: {
              slidesPerView: 5,
            },
          }}
        >
          <div className="swiper-wrapper ">
            {features.map(function (item, i) {
              return (
                <SwiperSlide className={styles.swiperDis} key={i}>
                  <Link to="/" className={styles[item.class]}>
                    <img
                      src={item.logo}
                      alt="#"
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default Features1;
