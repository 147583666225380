// DesignShowcase.js
import React from 'react';
import styles from './DesignShowcase.module.css';

const DesignShowcase = (props) => {
  return (
    <div className={`container ${styles.showcaseContainer} ${styles[props.reverse]}`}>
      <div className={styles.imageSection}>
        <img
          src={props.image}// Make sure to replace with your image path
          alt="Modern building"
          className={styles.showcaseImage}
        />
      </div>
      <div className={styles.contentSection}>
        <h1 className={styles.title}>{props.title}</h1>
        <p className={styles.description}>
         {props.text}
        </p>
        <ul className={styles.features}>
          <li><span>+</span> {props.keyOne}</li>
          <li><span>+</span>  {props.keyTwo}</li>
          <li><span>+</span> {props.keyThree}</li>
        </ul>
        <a href="mailto:info@boldstudio.design" className={styles.requestButton}>Request access</a>

       
      </div>
    </div>
  );
};

export default DesignShowcase;
