import React from "react";
import style from "./Before.module.css"

const Before = () =>{
    return(
        <div className={`subContainer ${style.beforeSection}`}>
            <h3>Shndërroni vizionin tuaj në realitet.
            </h3>
               <div className={style.beforeDis}>

               <div className={style.before}>
               <video autoPlay muted loop playsInline>
  <source src="/assets/images/bld.mp4" type="video/mp4" />
</video>
              </div>
          


            
               </div>
        </div>
    )
}

export default Before;