import React, { useRef } from "react";
import styles from "./Home.module.css";
import Lottie from "react-lottie-player";

import rubik from "../../lottie/rubik.json";
import { Link } from "react-router-dom";
import Before from "../../components/before/Before";
import DesignShowcase from "../../components/designShowcase/DesignShowcase";
// import LineAnimation from "../../components/lineAnimations/LineAnimation";
import LineAnimationTwo from "../../components/lineAnimationsTwo/LineAnimationTwo";
import LineAnimation from "../../components/lineAnimations/LineAnimation";
import FAQ from "../../components/faq/Faq";
import Features1 from "../../components/featured/Featured";

const Home = ({ homeRefs }) => {

  const carBrandingRef = useRef(null);
  const billboardRef = useRef(null);
  const ledScreensRef = useRef(null);
  const branding3DRef = useRef(null);
  return (
    <>
      <div className={styles.homeBck}>
        <LineAnimation/>
        <LineAnimationTwo/>
        <div className={styles.homeBanner}>
          
          <img src="/assets/images/logoo.png" className={styles.mainLogos}/>
          <span>STUDIO</span>
          <p>
          Në BOLD, ne kombinojmë inovacionin dhe saktësinë për të sjellë idetë tuaja në jetë përmes printimeve cilësore dhe zgjidhjeve reklamuese 3D. Ngrini nivelin e markës suaj me dizajne tërheqëse dhe vizuale mbresëlënëse për çdo projekt, nga materialet e marketingut deri te ekranet dinamike 3D.
          </p>
          <div className={styles.homeButtons}>
          <a href="mailto:info@boldstudio.design">Na kontaktoni</a>
          </div>
          <div className="rubik">
            <Lottie
              loop
              animationData={rubik}
              play
              className="lottieContainer"
              style={{ width: 450, height: 450 }}
            />
          </div>
        </div>
      </div>
      <Features1/>
      <Before />
      <div className={styles.callUs}>
        <div className="subContainer">
        <h2>Gati për të nisur projektin tuaj?
        </h2>
        <a href="mailto:info@boldstudio.design">Dërgo email</a>

        </div>
      </div>
      
      <div ref={homeRefs.billboardRef}>
        <DesignShowcase
          reverse="reverse"
          image="/assets/images/banner.webp"
          title="Dallohu me Billboarde Që Tërheqin Vëmendjen!"
          text="Lë një ndikim të madh me dizajnet e billboardeve me impakt të lartë nga Bold…"
          keyOne="Billboarde me Printim të Lartë – Dizajne të qarta dhe të gjalla për maksimumin e dukshmërisë."
          keyTwo="Billboarde Digjitale – Ekrane të programueshme për mesazhe dinamike."
          keyThree="Opsione Ekologjike – Materiale të qëndrueshme për një reklamim më të gjelbër."
        />
      </div>

      <div ref={homeRefs.ledScreensRef}>
        <DesignShowcase
          image="/assets/images/totem.webp"
          title="Ngritja e Një Simboli Unik për Kompaninë Tuaj"
          text="Një bashkëpunim mes teknologjisë dhe kreativitetit për të përfaqësuar vlerat tuaja."
          keyOne="Dizajni Inovativ
Krijimi i një totemi 3D që pasqyron kreativitetin dhe modernitetin e markës."
          keyTwo="Ndërtimi dhe Materialet
Përdorimi i materialeve të qëndrueshme dhe teknologjisë së fundit për të realizuar një strukturë të fortë dhe estetike."
          keyThree="Përfaqësimi i Identitetit të Kompanisë
Një simbol vizual që shpreh vlerat dhe misionin e kompanisë përmes dizajnit të saj unik."
        />
      </div>

      <div ref={homeRefs.branding3DRef}>
        <DesignShowcase
          reverse="reverse"
          image="/assets/images/inside.webp"
          title="Transformo hapësirën tënde me 3D që bie në sy!"
          text="Shndërro hapësirën tënde me magjinë e projeksioneve 3D!"
          keyOne="Projektime Kreative – Dizenjoni hapësira unike me projekte 3D që tërheqin vëmendjen."
          keyTwo="Mapping i Dritës – Krijoni efekte vizuale të mrekullueshme me teknologji 3D."
       keyThree="Kualitet që tërheq vëmendjen, dizajne që bëjnë përshtypje!"
        />
      </div>

      <div ref={homeRefs.ledScreensRef}>
        <DesignShowcase
          image="/assets/images/menu.webp"
          title="Materiale të Personalizuara për Prezantim Profesional"
          text="Fletushka, Vizitkarta dhe Stickera të Dizajnuara për Të Lënë Një Përshtypje Të Paharrueshme"
          keyOne="Identiteti vizual – Fletushkat, vizitkartat dhe stickerat janë mënyra të shkëlqyera për të krijuar një identitet të fortë vizual për biznesin tuaj.
"
          keyTwo="Marketing dhe Prezantim – Përdorimi i këtyre materialeve ndihmon në promovimin e shërbimeve ose produkteve tuaj në mënyrë të efektshme."
          keyThree="Krijimtari dhe Personalizim – Çdo material mund të personalizohet për të reflektuar stilin dhe mesazhin e markës tuaj, duke shtuar një prekje unike."
        />
      </div>


      <div ref={homeRefs.carBrandingRef}>
        <DesignShowcase
              reverse="reverse"
          image="/assets/images/car.jpg"
          title="Transformo Automjetin Tënd me Branding të Personalizuar të Makinave"
          text="Bold ofron branding profesional për të shndërruar automjetin tuaj në një reklamë në lëvizje..."
          keyOne="Wraps të Personalizuar – Pako tërheqëse të makinave të përshtatura për markën tuaj."
          keyTwo="Zgjidhje për Flotën – Branding i njëtrajtshëm në të gjitha automjetet."
          keyThree="Wraps Reflektues – Dizajne që duken gjatë 24/7 për dukshmëri maksimale."
        />
      </div>

      <FAQ/>

   
    </>
  );
};

export default Home;
