import React, { useState, useRef } from 'react';
import styles from './FAQ.module.css'; // Import the CSS module

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null); // Reference to the content (answer) for dynamic height

  return (
       <div className='subContainer'>
        <div className={styles.faqItem}>
      <div className={styles.faqQuestion} onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className={`${styles.faqToggle} ${isOpen ? styles.open : ''}`}>
          {isOpen ? '-' : '+'} {/* Change between + and - */}
        </span>
      </div>
      <div
        ref={contentRef}
        className={styles.faqAnswerWrapper}
        style={
          isOpen
            ? { height: contentRef.current?.scrollHeight + 'px' }
            : { height: '0px' }
        }
      >
        <div className={styles.faqAnswer}>{answer}</div>
      </div>
    </div>
       </div>
  );
};

const FAQ = () => {
  const faqData = [
    // Branding i Makinave
    { question: 'Çfarë është Branding i Makinave?', answer: 'Branding i Makinave e transformon automjetin tuaj në një reklamë lëvizëse, të personalizuar me dizajne që pasqyrojnë markën tuaj.' },
    { question: 'Sa i qëndrueshëm është wrap-i i automjetit?', answer: 'Wrap-ët e makinave janë bërë nga materiale të cilësisë së lartë që janë projektuar për t’u përballur me kushte të ndryshme të motit.' },
    { question: 'A mund ta heq ose ta ndryshoj wrap-in më vonë?', answer: 'Po, wrap-et e makinave mund të hiqen ose azhurnohen pa dëmtuar ngjyrën origjinale të automjetit.' },

    // Reklama në Billboard/Totem
    { question: 'Çfarë është Reklama në Billboard/Totem?', answer: 'Kjo formë reklame përdor vizuale në shkallë të gjerë për të kapur vëmendjen në zona me trafik të lartë.' },
    { question: 'Sa efektive është reklama në billboard?', answer: 'Billboard-et janë shumë të dukshme dhe arrijnë një audiencë të gjerë, duke i bërë ato efektive për ndërgjegjësimin e markës.' },
    { question: 'A janë të disponueshme materiale ekologjike?', answer: 'Po, ne ofrojmë mundësi të qëndrueshme për billboard-et për të mbështetur praktikat më të gjelbra të reklamimit.' },

    // Sinjalistika 3D
    { question: 'Çfarë është Sinjalistika 3D?', answer: 'Sinjalistika 3D përdor tabela dimensionale për të krijuar shfaqje vizuale që bien në sy.' },
    { question: 'Si instalohen sinjalistikët 3D?', answer: 'Ekipi ynë siguron instalimin e sigurt, dhe ne ofrojmë mundësi për brenda dhe jashtë ambienteve.' },
    { question: 'A mund të përfshijë sinjalistika 3D elemente interaktive?', answer: 'Po, ne ofrojmë mundësi të tilla si funksione holografike dhe realitet të shtuar.' },

    // Ekranet LED
    { question: 'Për çfarë përdoren Ekranet LED?', answer: 'Ekranet LED përdoren për të shfaqur përmbajtje dinamike dhe me rezolucion të lartë në hapësira brenda dhe jashtë.' },
    { question: 'A janë ekranet LED të përshtatshme për përdorim jashtë?', answer: 'Po, ekranet LED që ofrojmë janë rezistente ndaj motit dhe ofrojnë vizuale të ndritshme dhe të qarta për ngjarje jashtë.' },
    { question: 'A mund të përditësoj përmbajtjen në ekranin LED nga larg?', answer: 'Po, përmbajtja mund të menaxhohet dhe përditësohet nga larg me software të përshtatshëm.' }

  ];
  
  return (
    <div className={styles.faq}>
      <h2 className={styles.heading}>Frequent questions</h2>
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
